import React from 'react';
import ToolBox from './ToolBox';
import "./ToolForEveryone.css";
import brandSrc from "../../Images/Brand.svg"
import agencySrc from "../../Images/Agency.svg"
import multiLocationSrc from "../../Images/Multi-location.svg"

const ToolForEveryone = () => {
  const toolData = [
    {
      imgSrc: brandSrc,
      title: "SMBs",
      items: [
        "Manage all your Social media in-house like a pro.",
        "Post through web, mobile and browser extensions.",
        "Use detailed analytics to see what works and what doesn’t.",
        "RSS feed connections never lets you run out of content to share."
      ],
      link: "",
      linkText: "Learn More"
    },
    {
      imgSrc: agencySrc,
      title: "Agencies",
      items: [
        "Customize and publish content at scale using advanced capabilities.",
        "Collaborate with teams and clients using approval workflows.",
        "Securely manage client’s accounts without asking for credentials.",
        "Personalize the platform with 100% white labeling capabilities."
      ],
      link: "",
      linkText: "Learn More"
    },
    {
      imgSrc: multiLocationSrc,
      title: "Multi-Location Brands",
      items: [
        "Manage Social media for multiple locations from your dashboard.",
        "Collaborate with location-specific teams to publish relevant content.",
        "Manage reviews for your Google Business and Facebook Pages.",
        "Leverage content library to save evergreen and reusable content."
      ],
      link: "",
      linkText: "Learn More"
    }
  ];

  return (
    <div className="section-hm-bd innerpage">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12 text-center pg-title">
            <h2>A Tool for Everyone</h2>
            <p>The only social media tool customized to your needs</p>
          </div>
        </div>
        <div className="row pt-40">
          {toolData.map((tool, index) => (
            <ToolBox key={index} tool={tool} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ToolForEveryone;
