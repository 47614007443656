import React from 'react';
import './PageHeader.css';
import socialMediaMarketingGoalsSrc from "../../Images/Social-Media-Marketing-Goals.webp"
import clientLogoSrc from "../../Images/clients-logos.webp"

const PageHeader = () => {
  return (
  <>

    <div className="page-head v2-hmpg">
      <div className="container py-80">
        <div className="row align-items-center">
          {/* Left Column - Content */}
          <div className="col-lg-6 col-md-12 pl-0">
            <h1 className="mb-24">Scale Social Media Management With Ease</h1>
            <p>
              Schedule social content in seconds, plan campaigns effectively, collaborate with your teams effortlessly, and monitor<br />
              the performance timely with SmartSocial.
            </p>
          </div>

          {/* Right Column - Image */}
          <div className="col-lg-6 col-md-12 px-0 media-top42">
            <img
              decoding="async"
              className="w-100"
              title="Social Media Marketing Goals"
              src={socialMediaMarketingGoalsSrc}
              alt="Social Media Marketing Goals"
            />
          </div>
        </div>
        
    
      </div>
        {/* Client logos section */}

    <div className="sp_clentlogos align-center">
        <div className="sp_logo_items">
        <div className="container py-56 silderMedia32">
      <div className="row">
        <div className="col-md-12 show-web">
          <img
            decoding="async"
            className="img-fluid w-100 aligncenter"
            src={clientLogoSrc}
            alt="global brands"
            width="942"
            height="103"
          />
        </div>
      </div>
      
    </div>
    </div>
  </div>
      
    </div>

    
  </>
  );
};

export default PageHeader;
