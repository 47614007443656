import React from 'react';

const G2Logo = ({ src, alt, width, height }) => {
  return (
    <div className="col-auto">
      <img
        loading="lazy"
        decoding="async"
        width={width}
        height={height}
        className="img-fluid"
        src={src}
        alt={alt}
      />
    </div>
  );
};

export default G2Logo;
