import React from 'react';
import G2Logo from './G2Logo';
import G2Rating from './G2Rating';
import "./G2Recommendation.css";
import G2LogoSrc from "../../Images/g2-logo.svg"
import G2Logo2Src from "../../Images/g2-logo2.svg"
import G2RatingSrc from "../../Images/g2-star.svg"

const G2Recommendation = () => {
  return (
    <div className="innerpage-bg section-hm-bd">
      <div className="container py-80">
        <div className="row g2-header">
          <div className="col-md-12 pg-title48 pg-title">
            <h2 className>Highly Recommended on G2</h2>
          </div>
        </div>
        <div className="row align-items-center row-gap-24 row-media991-center">
          <G2Logo
            src={G2LogoSrc}
            alt="G2 logo"
            width="409"
            height="122"
          />
          <G2Rating
            rating="4.5"
            starImageSrc={G2RatingSrc}
          />
          <G2Logo
            src={G2Logo2Src}
            alt="G2 logo"
            width="410"
            height="122"
          />
        </div>
      </div>
    </div>
  );
};

export default G2Recommendation;
