import React from 'react';

const G2Rating = ({ rating, starImageSrc }) => {
  return (
    <div className="col mediah-991">
      <div className="g2-reg" style={{ background: '#F4F9FF' }}>
        <div className="gr-retgbox">
          <p className="gr-retg">{rating}</p>
          <div className="g2-star">
            <img
              loading="lazy"
              decoding="async"
              width="120"
              height="24"
              className="img-fluid"
              src={starImageSrc}
              alt="star"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default G2Rating;
