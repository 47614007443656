import React from 'react';

const ToolBox = ({ tool }) => {
  return (
    <div className="col-md-12 col-lg-4">
      <div className="bd-box">
        <div className="bd-boximg">
          <img
            loading="lazy"
            decoding="async"
            title={tool.title}
            src={tool.imgSrc}
            alt={tool.title}
            width="200"
            height="130"
          />
        </div>
        <h3 className="text-center">{tool.title}</h3>
        <ul>
          {tool.items.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
        <p className="mb-0 mt-lg-3 text-center">
          {/* <a className="readmoreicon" title={tool.title} href={""}>
            {tool.linkText}
          </a> */}
        </p>
      </div>
    </div>
  );
};

export default ToolBox;
