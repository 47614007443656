import React, { useState } from 'react';
import './Header.css';
import { Link, useNavigate } from 'react-router-dom';


const Header = () => {
  const [isMenuVisible] = useState(false);
  const navigate = useNavigate();




  return (
    <div className="header-container border-bottom">
      <div className="container position-relative">
        <div className="row">
          <div className="col-lg-2 custom-logo media-center">
            <Link to="/" style={{ borderWidth: '0px', border: '0px' }}>
              <img
                src={"https://sp-production-image.s3.amazonaws.com/129873/e9145567-ae89-45eb-a9e2-f8c5ea44e552.png"}
                height="38"
                alt="smartsocial"
                title="smartsocial"
                loading="lazy"
                className="media-hide"
              />
              <img
                src="https://www.smartsocial.co/wp-content/uploads/2022/12/smartsocial-icon.svg"
                width="50"
                height="30"
                alt="smartsocial"
                title="smartsocial"
                loading="lazy"
                className="web-hide float-left"
                style={{ width: 'auto', height: '30px' }}
              />
            </Link>
            <h2 onClick={() =>{navigate("/")}} style={{color:"#E94647",marginLeft:"10px", cursor:"pointer"}}>Smart Social  </h2>
          </div>

          <div className="col-lg-10 position-static pr-0 ml-auto">
            <nav>
              <ul className={`exo-menu ${isMenuVisible ? 'visible' : ''}`} id="exo-menu">

                <li className="mega-drop-down first-4-menu">
                <Link to="/privacy-policy">Privacy Policy</Link>

                </li>
                <li className="mega-drop-down first-4-menu">
                  <Link to="/terms">Terms and Conditions</Link>
                </li>
                <li className="mega-drop-down first-4-menu">
                  <a href="https://app.smartsocial.us/login">Login</a>
                </li>

                
              </ul>

            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
