import React from 'react';
import StatCard from './StatCard';
import "./MainStatsPage.css";
import postsSrc from "../../Images/posts.svg"
import usersSrc from "../../Images/users.svg"
import ratedSrc from "../../Images/rated.svg"

const MainStatsPage = () => {
  const statsData = [
    {
      imgSrc: postsSrc,
      title: "13,000+ customers",
      subtitle: "and counting",
      description: "Our customer base is growing exponentially every passing day."
    },
    {
      imgSrc: usersSrc,
      title: "10M+ posts & reels",
      subtitle: "published monthly",
      description: "Helping brands and agencies take social media publishing up a notch."
    },
    {
      imgSrc: ratedSrc,
      title: "4.5 rated",
      subtitle: "on G2",
      description: "Smart Social is rated highly on G2 – the largest review site in the world."
    }
  ];

  return (
    <div className=" innerpage-bg section-hm-bd">
      <div className="container">
        <div className="row">
          {statsData.map((stat, index) => (
            <StatCard key={index} stat={stat} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default MainStatsPage;
