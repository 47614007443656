import React from 'react';
import './Footer.css'; // Make sure to import the CSS file
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className="page-template-page-new">
      <div className="footer-container custom-footer-top">

          {/* Footer Info Section */}
          <div className="footer-lstliun">
            <div className="container-md">
              <div className="row">
                <div className="col-auto cpy-txt">
                  <ul>
                    <li>© 2024 SmartSocial.us All Rights Reserved.</li>
                    <li><Link to="/privacy-policy">Privacy Policy & GDPR</Link></li>
                    <li><Link to="/terms">Terms of Service</Link></li>
                  </ul>
                </div>

              </div>
            </div>
          </div>
        </div>
    </div>
  );
};

export default Footer;
