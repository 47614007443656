import React from 'react';
import './PageContent.css';
import easyToUseSrc from "../../Images/Easy-to-use.svg"
import rotateIconSrc from "../../Images/360-degree.svg"
import considerableSavingSrc from "../../Images/Considerable-savings.svg"
import exceptionalSupportSrc from "../../Images/Exceptional-support.svg"
 

const PageContent = () => {
  return (
    <div className="section-inner lndv2">
      

      <div className="section-hm-bd">
        <div className="container">
          <div className="row">
            <div className="col-md-12 pg-title">
              <h2>Why Smart Social?</h2>
            </div>
          </div>
          <div className="row row-gap-30">
            <div className="col-md-12 col-lg-6">
              <div className="btterxbox">
                <div className="btterximg">
                  <img
                    decoding="async"
                    title="easy to use"
                    src={easyToUseSrc}
                    alt="easy to use"
                    width="58"
                  />
                </div>
                <div className="btterxtxt">
                  <h3>Easy to use, no learning curve</h3>
                  <p>
                    Navigate an intuitive interface that makes it easy to onboard team members, manage client approvals, and schedule posts with zero hassle.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6">
              <div className="btterxbox">
                <div className="btterximg">
                  <img
                    decoding="async"
                    title="360-degree"
                    src={rotateIconSrc}
                    alt="360-degree"
                    width="58"
                  />
                </div>
                <div className="btterxtxt">
                  <h3>360-Degree view of your performance</h3>
                  <p>
                    Robust reporting tools make it easy for your team members and clients to access complete analytics for multiple social media profiles at any time.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6">
              <div className="btterxbox">
                <div className="btterximg">
                  <img
                    decoding="async"
                    title="Considerable savings"
                    src={considerableSavingSrc}
                    alt="Considerable savings"
                    width="58"
                  />
                </div>
                <div className="btterxtxt">
                  <h3>More savings with no compromise</h3>
                  <p>
                    Save countless hours every month and spend significantly less with an all-in-one solution that caters to every step of your social media strategy.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6">
              <div className="btterxbox">
                <div className="btterximg">
                  <img
                    decoding="async"
                    title="Exceptional support"
                    src={exceptionalSupportSrc}
                    alt="Exceptional support"
                    width="58"
                  />
                </div>
                <div className="btterxtxt">
                  <h3>Exceptional support on demand</h3>
                  <p>
                    Top-notch customer support from day one on phone, email, chat, and app, so you can get all your queries resolved in real time.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageContent;
