import React from 'react';

const StatCard = ({ stat }) => {
  return (
    <div className="col-lg-4 col-md-12">
      <div className="h-inerbox">
        <div className="h-inerimg">
          <img
            loading="lazy"
            decoding="async"
            title={stat.title}
            src={stat.imgSrc}
            alt={stat.title}
            width="62"
            height="61"
          />
        </div>
        <div className="h-inertxt">
          <h2>{stat.title}</h2>
          <h4>{stat.subtitle}</h4>
          <p>{stat.description}</p>
        </div>
      </div>
    </div>
  );
};

export default StatCard;
